import { forwardRef } from "react";
import { InputWrapperProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import dynamic from "next/dynamic";
import { useId } from "react-id-generator";
import type PhoneInputLib from "react-phone-number-input";
//@ts-ignore
import nl from "react-phone-number-input/locale/nl";
import styled from "styled-components";

import "react-phone-number-input/style.css";

import { InputWrapper } from "../Forms";

const DynamicPhoneInput = dynamic(() => import("react-phone-number-input"), {
  ssr: false,
}) as typeof PhoneInputLib;

const Wrapper = styled.div<{ error?: any }>`
  .PhoneInputCountry,
  .PhoneInputInput {
    border-radius: 8px;
    appearance: none;
    display: inline-flex;
    background-color: #fff;
    min-height: 48px;
    font-size: ${(p) => p.theme.fontSizes[2]}px;
    border: 1px solid ${({ theme }) => theme.colors.grey400};

    transition: border-color 0.18s ease-in-out,
      background-color 0.18s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.colors.grey800};
    }
  }

  .PhoneInputCountry {
    margin-right: ${(p) => p.theme.space[1.5]}px;
    padding: ${(p) => p.theme.space[1]}px;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: none;

    > img {
      border-radius: 2px;
    }
  }

  .PhoneInputCountrySelect
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: ${({ theme }) => theme.colors.grey600};
    border-color: ${({ theme }) => theme.colors.grey600};
  }

  .PhoneInputCountrySelect {
    &:hover,
    &:active,
    &:focus {
      & + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
        color: ${({ theme }) => theme.colors.grey800};
        border-color: ${({ theme }) => theme.colors.grey800};
      }
    }
  }

  .PhoneInputInput {
    text-decoration: none;
    padding: 11px ${(p) => p.theme.space[1.5]}px 10px;
    color: ${({ theme }) => theme.colors.grey800};

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey400};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;

      &:hover {
        border-color: ${({ theme }) => theme.colors.grey600};
      }
    }
  }
`;

export type PhoneInputProps = {
  defaultCountry?: string;
  id?: string;
  required?: boolean;
} & InputWrapperProps;

export const PhoneInput = forwardRef(
  (
    {
      status,
      statusMessage,
      label,
      error,
      id: givenId,
      ...rest
    }: PhoneInputProps,
    ref
  ) => {
    const autoId = useId();
    const id = givenId || `select-${autoId}`;

    return (
      <Wrapper>
        <InputWrapper
          id={id}
          label={label}
          status={status}
          statusMessage={statusMessage}
          error={error}
          {...pick(rest)}
        >
          <DynamicPhoneInput
            countries={["BE", "NL", "DE"]}
            labels={nl}
            ref={ref}
            id={id}
            {...omit(rest)}
          />
        </InputWrapper>
      </Wrapper>
    );
  }
);
