import { useCallback, useState } from "react";
import { Box, Flex, Icon } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { Heading, Text, Container, Button } from "../../../components";
import { Checkmark } from "../Icons";
import { EmailPopup } from "../Popups/EmailPopup";
import { NewsletterForm } from "./NewsletterForm";
import { usePostHog } from "posthog-js/react";

type NewsletterBannerProps = {
  title: string;
  description: string;
  message: string;
  placeholder: string;
  button: string;
};

export const NewsletterBanner = ({
  title,
  description,
  message,
  placeholder,
  button,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);
  const posthog = usePostHog();
  return (
    <Container bg="grey100" pt={0} pb={0} px={2} maxWidth={"1360px"}>
      <Flex
        flexDirection={["column", "column", "row"]}
        width="100%"
        justifyContent={["space-between", "space-between", "center"]}
        alignItems="center"
      >
        <Box
          maxWidth={["none", "none", 480, 660]}
          py={[5, 8]}
          pr={[0, 0, 3, 20]}
        >
          <Heading
            variant="h3"
            as="h3"
            fontSize={[3.5, 3.5, 3]}
            mb={2}
            fontWeight="medium"
            textAlign={["center", "center", "left"]}
          >
            {title}
          </Heading>
          <Text mb={4} textAlign={["center", "center", "left"]} fontSize={2}>
            {description}
          </Text>
          {/* <NewsletterForm placeholder={placeholder} button={button} /> */}
          <Button
            mb={2}
            onClick={() => {
              setIsOpen(true);
              // posthog.capture("Clicked Newsletter Button Homepage");
            }}
          >
            Aanmelden
          </Button>
          <Flex
            alignItems="center"
            justifyContent={["center", "center", "left"]}
          >
            <Icon icon={Checkmark} color="grey600" />
            <Text fontSize={1.75} color="grey600">
              {message}
            </Text>
          </Flex>
        </Box>

        <Flex
          alignItems="flex-start"
          justifyContent="center"
          alignSelf="flex-end"
          maxHeight={[240, 240, 320]}
          height={[240, 240, "auto"]}
          width={["calc(100% + 32px)", "100%", "auto"]}
          flex="1"
          overflow="hidden"
          position="relative"
          mx={[-2, 0, 0]}
        >
          <Box
            backgroundColor="white"
            borderRadius="50%"
            minWidth={[420, "100%", "100%"]}
            pt={[420, "100%", "100%"]}
            height="0"
            mt={2}
          />
          <Box
            height="100%"
            width={["100%", "100%", "70%"]}
            position="absolute"
            bottom={0}
            left="50%"
            pt={4}
            transform="translateX(-50%)"
            css={{
              ".storyblok-image-wrapper": {
                width: "100%",
                height: "100%",
              },
              img: {
                objectPosition: "center bottom !important",
              },
            }}
          >
            <Image
              src="https://a.storyblok.com/f/125270/718x658/c2e8a60597/fruiting-branchlet-of-scorpiurus-vermiculata.png"
              fit="contain"
              fluid={350}
              alt="Botanical drawing of a plant"
              showPlaceholder={false}
              loading="lazy"
            />
          </Box>
        </Flex>
      </Flex>
      <EmailPopup isOpen={isOpen} close={close} />
    </Container>
  );
};
