import { useState } from "react";
import { Box, Flex, Icon, Text, css } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { usePostHog } from "posthog-js/react";
import { getLinkProps } from "~lib";
import { ArrowDown, ArrowRight, ArrowUp } from "~components/common/Icons";
import { NavigationMegaSubLinksNew } from "./NavigationMegaSubLinksNew";

export const ShopMenu = ({ shop, close }) => {
  const [tab, setTab] = useState(shop.link_list[0]);
  const posthog = usePostHog();
  return (
    <Box
      top={50}
      position={"absolute"}
      width={"100%"}
      bg="white"
      borderRadius={"6px"}
    >
      <Flex>
        <Box>
          {shop.link_list.map((item) => (
            <Flex
              key={item._uid}
              width={"282px"}
              borderBottom={"1px solid #FCF9F7"}
              height={"50px"}
              bg={item?._uid === tab?._uid && "#FCF9F7"}
              onClick={() => {
                // posthog.capture("Clicked Menu", {
                //   tab: "Shop",
                //   item:
                //     item?.mega_link_item_3columns_label ||
                //     item.mega_link_item_group_label,
                // });

                if (item.component === "mega_link_item_no_columns") {
                  window.location.href = getLinkProps(
                    item.mega_link_item_group_url
                  );
                  close();
                  return;
                }
                if (item?._uid === tab?._uid) setTab(null);
                else setTab(item);
              }}
              pl="20px"
              cursor={"pointer"}
            >
              <Flex
                flex={1}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Flex
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Text
                    css={{ textTransform: "uppercase" }}
                    fontFamily={"DINPro"}
                    fontWeight={500}
                    fontSize={"15px"}
                  >
                    {item?.mega_link_item_3columns_label ||
                      item.mega_link_item_group_label}
                  </Text>
                  {(item?.mega_link_item_3columns_image?.filename ||
                    item?.mega_link_item_group_image?.filename ||
                    item?.image?.filename) && (
                    <Box
                      css={css({
                        img: {
                          borderRadius: "md",
                        },
                      })}
                    >
                      <Image
                        width={50}
                        height={40}
                        fluid={100}
                        src={
                          item?.mega_link_item_3columns_image?.filename ||
                          item?.mega_link_item_group_image?.filename ||
                          item?.image?.filename
                        }
                      />
                    </Box>
                  )}
                </Flex>
                <Box width={"43px"} pl="15px" pt={"3px"} flexShrink={0}>
                  {item?._uid === tab?._uid && (
                    <Icon transform="rotate(90deg)" icon={ArrowUp}></Icon>
                  )}
                </Box>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Box width={"100%"} bg="#FCF9F7">
          {tab && <NavigationMegaSubLinksNew megaData={tab} close={close} />}
        </Box>
      </Flex>
    </Box>
  );
};
