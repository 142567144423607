import { Fragment, ReactNode, useState } from "react";
import { Box, Flex, Icon, SystemProps } from "@storyofams/react-ui";

import Link from "next/link";
import { useShopify } from "~context";
import { Image, Text } from "../../../components";

import { shopifyToIntlPrice } from "../../../lib/shopify/utils";
import { Heading } from "../Heading";
import { ArrowDown, ArrowUp } from "../Icons";
import DiscountInfo from "./DiscountInfo";
import { RemoveButton } from "./RemoveButton";

export const LineItem = ({
  id,
  merchandise,
  isInModal,
  quantity,
  tiered_enabler,
  discountAllocations,
  lineComponents,
  cost,
  attributes,
  children,
  ...props
}: any & {
  children: ReactNode;
  tiered_enabler?: boolean;
  isInModal?: boolean;
} & SystemProps) => {
  const discountTag = merchandise.product.tags.find((item) =>
    item.includes("td")
  );
  const { customerCompanyId } = useShopify();
  const getAttributes = () => {
    return attributes
      .map(({ key, value }) => {
        if (key === "Recipient name") return `Ontvanger naam: ${value}`;
        if (key === "Recipient email") return `Ontvanger email: ${value}`;
        else return null;
      })
      .filter((item) => item);
  };
  const fullPrice = lineComponents
    ?.reduce((acc, item) => Number(item?.merchandise.priceV2?.amount) + acc, 0)
    .toFixed(2);
  return (
    <>
      <Flex alignItems={"center"} as="a" {...props}>
        <Box alignSelf={"start"}>
          <Link
            href={
              merchandise?.product?.metafield?.value
                ? `/recipes/${merchandise?.product?.metafield?.value}`
                : `/products/${merchandise?.product?.handle}`
            }
            passHref
          >
            <Image
              cursor="pointer"
              src={merchandise?.image?.originalSrc}
              alt={merchandise?.image?.altText || merchandise?.product?.title}
              width={isInModal ? 84 : [84, 130]}
              height={isInModal ? 100 : [100, 160]}
              layout={merchandise?.product?.productType === "Bundle" && "fill"}
              mr={1.5}
              overflow="hidden"
              objectFit="contain"
              flexShrink={0}
              border="1px"
              borderColor="grey200"
            />
          </Link>
        </Box>

        <Flex flexDirection="column" width="100%">
          <Flex
            flex={1}
            justifyContent="space-between"
            alignItems="flex-start"
            mb={1.5}
          >
            <Box>
              <Link
                href={
                  merchandise?.product?.metafield?.value
                    ? `/recipes/${merchandise?.product?.metafield?.value}`
                    : `/products/${merchandise?.product?.handle}`
                }
                passHref
              >
                <Heading
                  cursor="pointer"
                  as="h5"
                  variant="h5"
                  fontSize={isInModal ? [1.75, 1.75] : [2.25, 2.25]}
                  mb={0.25}
                  fontWeight="medium"
                  css={{ textTransform: "uppercase" }}
                >
                  {merchandise?.product?.title}
                </Heading>
              </Link>
              {!!merchandise?.selectedOptions?.length &&
                (merchandise?.selectedOptions?.[0]?.name !== "Title" ||
                  merchandise?.selectedOptions?.length > 1) && (
                  <Text fontSize={[1.5]} color="grey600">
                    {merchandise?.selectedOptions
                      ?.map((opt) => `${opt.name}: ${opt.value}`)
                      .join(" - ")}
                  </Text>
                )}
              {attributes &&
                getAttributes().map((item) => (
                  <Text key={item} fontSize={[1.5]} color="grey600">
                    {item}
                  </Text>
                ))}
            </Box>

            <RemoveButton
              variant={{ id, merchandise }}
              display={!isInModal && ["flex", "none"]}
            />
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            {merchandise?.compareAtPriceV2?.amount ||
            cost?.subtotalAmount?.amount !== cost?.totalAmount?.amount ? (
              <Box display="flex">
                <Text fontWeight={"medium"} mr={1}>
                  {shopifyToIntlPrice(cost.totalAmount)}
                </Text>
                <Text
                  textDecoration="line-through"
                  fontWeight={"medium"}
                  color="gray"
                >
                  {shopifyToIntlPrice(
                    merchandise?.compareAtPriceV2 || cost?.subtotalAmount
                  )}
                </Text>
              </Box>
            ) : fullPrice && fullPrice > cost.totalAmount.amount ? (
              <Box display="flex">
                <Text fontWeight={"medium"} mr={1}>
                  {shopifyToIntlPrice(cost.totalAmount)}
                </Text>
                <Text
                  textDecoration="line-through"
                  fontWeight={"medium"}
                  color="gray"
                >
                  {"€" + fullPrice}
                </Text>
              </Box>
            ) : (
              <Text fontWeight={"medium"}>
                {shopifyToIntlPrice(cost.totalAmount)}
              </Text>
            )}
            {children}
          </Flex>
          {quantity >= 2 &&
            discountTag &&
            tiered_enabler &&
            !customerCompanyId && (
              <DiscountInfo
                variant={merchandise}
                discountTag={discountTag}
                quantity={quantity}
              />
            )}
          <Box display={["none", "block"]}>
            <RecipeItem lineComponents={lineComponents} quantity={quantity} />
          </Box>
        </Flex>
      </Flex>
      <Box display={["block", "none"]}>
        <RecipeItem lineComponents={lineComponents} quantity={quantity} />
      </Box>
    </>
  );
};
const RecipeItem = ({ lineComponents, quantity }) => {
  const [isShowRecipe, setIsShowRecipe] = useState(true);
  return (
    <Fragment>
      {lineComponents && (
        <Flex
          mt={1}
          onClick={() => setIsShowRecipe((show) => !show)}
          alignItems={"center"}
        >
          <Text color="grey400">{`${lineComponents.length} artikelen`}</Text>
          <Icon
            fontSize={"14px"}
            pt="3px"
            ml={0.5}
            opacity={0.3}
            icon={isShowRecipe ? ArrowDown : ArrowUp}
          />
        </Flex>
      )}
      {lineComponents && isShowRecipe && (
        <Box>
          {lineComponents.map(({ merchandise }) => (
            <Link
              prefetch={false}
              key={merchandise.id}
              href={`/products/${merchandise.product.handle}`}
            >
              <Flex mt="5px" alignItems={"center"} cursor={"pointer"}>
                <Box border="1px" borderRadius="md" borderColor="grey200">
                  <Image
                    src={merchandise?.image?.url}
                    alt={
                      merchandise?.image?.altText || merchandise?.product?.title
                    }
                    width={35}
                    height={35}
                    // layout="fill"
                    overflow="hidden"
                    objectFit="cover"
                  />
                </Box>

                <Text
                  fontSize={"14px"}
                  fontFamily={"DINPro"}
                  ml={1}
                  mb={0.25}
                  fontWeight="medium"
                >
                  {`${quantity} x ${merchandise?.product?.title} ${
                    merchandise.title !== "Default Title"
                      ? `- ${merchandise.title}`
                      : ""
                  } `}
                </Text>
              </Flex>
            </Link>
          ))}
        </Box>
      )}
    </Fragment>
  );
};
