import { Fragment, useMemo, useState } from "react";
import { Box, Flex, Icon, RadioGroup, Text, css } from "@storyofams/react-ui";
import { createClient } from "@supabase/supabase-js";
import axios from "axios";
import Image from "next/image";
import { useShopify } from "~context";
import { getLinkProps } from "~lib";
import { Button } from "../Button";
import { ArrowLeft, ArrowRight } from "../Icons";
import { Input } from "../Input";
import { Radio } from "../Radio";
import { RichText } from "../RichText";
import { Modal } from "../Modal";

export const FeedbackPopup = ({ content }) => {
  const [textQuestion, setTextQuestion] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { customer } = useShopify();
  const [questions, setQuestions] = useState(() =>
    content.questions.map((item) => ({
      label: item.label,
      value: item.questions[0].title,
    }))
  );
  const [page, setPage] = useState(1);
  const pages = useMemo(
    () =>
      content.simpleQuestion
        ? content?.questions.length + 1
        : content.questions.length,
    [content]
  );
  const questionsHandler = (question, title) => {
    setQuestions((items) => [
      ...items.filter(({ label }) => label !== question),
      { label: question, value: title },
    ]);
  };
  const check = (label, title) => {
    return Boolean(
      questions.find((item) => item.label === label && item.value === title)
    );
  };
  const sendFeedback = async () => {
    const supabase = createClient(
      process.env.NEXT_PUBLIC_SUPABASE_URL,
      process.env.NEXT_PUBLIC_SUPABASE_KEY
    );
    const sendingData = questions.map(({ label, value }) => ({
      user_email_id: customer?.emailAddress.emailAddress,
      question_text: label,
      answer_text: value,
    }));
    try {
      const { data, error } = await supabase
        .from("website_feedback_data")
        .insert([
          ...sendingData,
          {
            user_email_id: customer?.emailAddress.emailAddress,
            question_text: content?.simpleQuestion,
            answer_text: textQuestion,
          },
        ]);
      if (!error) {
        const { data: stampedCustomer } = await axios.get(
          `/api/stamped/getCustomer?email=${customer.emailAddress.emailAddress}`
        );
        const id = stampedCustomer?.results[0]?.customer?.id;
        if (id) {
          await axios.post(
            `/api/stamped/givePoints?id=${id}&points=20&reason=feedback`
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Modal
      overflow="visible"
      isOpen={isOpen}
      close={() => {
        setIsOpen(false);
      }}
    >
      <Box
        p={["20px", "30px"]}
        bg="#F7EFE7"
        position={"relative"}
        borderRadius={"8px"}
      >
        <Box
          mx="auto"
          top={-8}
          left={0}
          right={0}
          width={130}
          height={130}
          position={"absolute"}
        >
          {content?.hero?.filename && (
            <Image layout="fill" src={content?.hero?.filename} />
          )}
        </Box>
        <Box>
          {content?.image?.filename && (
            <Box
              width={"100%"}
              height={"200px"}
              mt={3}
              position={"relative"}
              css={css({
                img: {
                  borderRadius: "md",
                },
              })}
            >
              <Image
                objectFit="cover"
                layout="fill"
                src={content?.image.filename}
              />
            </Box>
          )}
          <Text
            textAlign={["center", "start"]}
            fontFamily={"DINPro"}
            fontWeight={700}
            fontSize={"28px"}
            mb={"20px"}
            mt={content?.hero?.filename ? "30px" : [2, 0]}
          >
            {content?.title.toUpperCase()}
          </Text>
          <RichText
            textAlign={["center", "start"]}
            text={content?.body}
            mb={3}
            borderBottom="1px solid grey200"
            pb="25px"
          />
          <Box display={["none", "block"]}>
            {content.questions.map((item, i) => (
              <Box key={i} mb={2} pb={"25px"} borderBottom="1px solid grey200">
                <Text fontWeight={600} fontSize={"18px"} mb={"12px"}>
                  {item.label}
                </Text>
                <Flex>
                  {item.questions.map((question) => (
                    <Box key={question.title} mr={"20px"}>
                      <Radio
                        width="16px"
                        height="16px"
                        label={question.title}
                        value={question.title}
                        checked={check(item.label, question.title)}
                        onChange={() =>
                          questionsHandler(item.label, question.title)
                        }
                        readOnly
                        disabled={false}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            ))}
            <Text fontWeight={600} mb="10px" fontSize={"18px"}>
              {content?.simpleQuestion}
            </Text>
            <Input
              value={textQuestion}
              onChange={(e) => setTextQuestion(e.target.value)}
              as="textarea"
              rows={3}
              mb={2}
            />
            <Button
              onClick={sendFeedback}
              width={"100%"}
              height={"48px"}
              variant="primary"
            >
              {content?.cta}
            </Button>
          </Box>
          <Box display={["block", "none"]}>
            {page > content.questions.length ? (
              <Box>
                <Text fontWeight={600} mb="10px" fontSize={"18px"}>
                  {content?.simpleQuestion}
                </Text>
                <Input
                  value={textQuestion}
                  onChange={(e) => setTextQuestion(e.target.value)}
                  as="textarea"
                  rows={3}
                  mb={2}
                />
              </Box>
            ) : (
              <Box>
                <Text fontWeight={600} fontSize={"18px"}>
                  {questions[page - 1]?.label}
                </Text>
                <Flex flexDirection={"column"}>
                  {content.questions[page - 1] &&
                    content.questions[page - 1].questions.map((question) => (
                      <Box key={question.title} mt={"15px"}>
                        <Radio
                          width="16px"
                          height="16px"
                          label={question.title}
                          value={question.title}
                          checked={check(
                            questions[page - 1].label,
                            question.title
                          )}
                          onChange={() =>
                            questionsHandler(
                              questions[page - 1].label,
                              question.title
                            )
                          }
                          readOnly
                          disabled={false}
                        />
                      </Box>
                    ))}
                </Flex>
              </Box>
            )}
            <Flex mt={2} justifyContent={"space-between"} alignItems={"center"}>
              <Button
                onClick={() => setPage((page) => page - 1)}
                variant="unstyled"
                width={30}
                height={30}
                border="1px solid black"
                borderRadius={"full"}
                disabled={page === 1}
                px={"5px"}
                pt="4px"
              >
                <Icon icon={ArrowLeft} />
              </Button>
              <Text> {`Vraag ${page} van ${pages}`}</Text>
              <Button
                onClick={() => setPage((page) => page + 1)}
                variant="unstyled"
                width={30}
                height={30}
                border="1px solid black"
                borderRadius={"full"}
                disabled={page === pages}
                px={"5px"}
                pt="4px"
              >
                <Icon icon={ArrowRight} />
              </Button>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
