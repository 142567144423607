import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import { Select, InputWrapperProps } from "@storyofams/react-ui";
import type { countries as countriesList } from "countries-list";
import { Props } from "react-select";

type SelectProps = {
  id?: string;
  isCompanyForm?: boolean;
} & InputWrapperProps &
  Props;

const Input = forwardRef(
  (
    { countries, ...props }: SelectProps & { countries: typeof countriesList },
    ref
  ) => {
    const options = useMemo(
      () =>
        Object.keys(countries)
          .map((code) => ({
            label: countries[code].name,
            value: code,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      []
    );
    return (
      <Select
        ref={ref}
        options={options}
        width="100%"
        {...props}
        value={
          typeof props.value === "string"
            ? options?.find(({ value }) => value === (props.value as any))
            : props.value
        }
        defaultValue={options?.find(
          ({ value }) => value === (props.defaultValue as any)
        )}
        isSearchable
        css={`
          border: 1px solid #c1bebd;
          border-radius: 8px !important;
          & > div {
            min-height: 47px;
            border-radius: 8px !important;
            border-color: ${({ theme }) => theme.colors.grey400};
            font-size: 16px;
            transition: border-color 0.18s ease-in-out,
              background-color 0.18s ease-in-out;

            &:not(:disabled) {
              &:hover,
              &:active,
              &:focus {
                border-color: ${({ theme }) => theme.colors.grey800};
              }
            }
          }
        `}
      />
    ) as any;
  }
);

const DynamicInput = forwardRef(({ ...props }: SelectProps, innerRef) => {
  const [countries, setCountries] = useState(null);

  const loadCountries = async () => {
    const loadedCountries = (await import("countries-list")).countries;

    setCountries(loadedCountries);
  };
  useEffect(() => {
    loadCountries();
  }, []);

  if (!countries) {
    return null;
  }

  return (
    <Input
      {...props}
      ref={innerRef}
      countries={
        props.isCompanyForm
          ? {
              BE: {
                name: "België",
              },
              NL: {
                name: "Nederland",
              },
              DE: {
                name: "Duitsland",
              },
            }
          : countries
      }
    />
  );
});

export const CountrySelect = forwardRef((props: SelectProps, ref) => {
  return <DynamicInput innerRef={ref} {...props} />;
});
