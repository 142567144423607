import { useCallback, useEffect, useState } from "react";
import { Flex, Icon, css, SystemProps, Box } from "@storyofams/react-ui";
import { debounce } from "lodash";

import { useShopify } from "~context";
import {
  CartFragmentFragment,
  CheckoutFragmentFragment,
} from "~lib/shopify/sdk";

import { Minus, Plus } from "../../common/Icons";
import { Button } from "../Button";
import { Text } from "../Text";

const StyledButton = ({ type = "minus", ...props }) => (
  <Button
    variant="unstyled"
    width="32px"
    height="32px !important"
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="relative"
    transition="0.2s border-color ease-out"
    css={css({
      "&:hover, &:focus": {
        borderColor: "grey800",
      },
    })}
    {...props}
  >
    {type === "plus" ? (
      <Icon className="checkmark" icon={Plus} fontSize={1.75} />
    ) : (
      <Icon className="checkmark" icon={Minus} fontSize={1.75} />
    )}
  </Button>
);

interface CheckoutLineItemProps {
  item: CartFragmentFragment["lines"]["edges"][number]["node"];
}

export const QuantityInput = ({
  item,
  ...props
}: CheckoutLineItemProps & SystemProps) => {
  const { adjustLineItemQuantity, cart, updatingCart } = useShopify();
  const [quantityInput, setQuantity] = useState(item.quantity);
  const debouncedAdjustQuantity = useCallback(
    debounce(adjustLineItemQuantity, 100),
    [cart]
  );
  const quantity = item?.quantity;
  const isSample = Boolean(
    item?.merchandise?.metafields.find((prod) => prod?.key === "sample_tier")
  );
  useEffect(() => {
    setQuantity(quantity);
  }, [quantity]);
  return (
    <Flex
      alignItems="center"
      borderWidth="2px"
      borderStyle="solid"
      borderColor="grey800"
      borderRadius="md"
      cursor={updatingCart ? "wait !important" : undefined}
    >
      <StyledButton
        disabled={quantity <= 0 || isSample}
        onClick={(e) => {
          e.preventDefault();
          debouncedAdjustQuantity(
            [
              {
                id: item.id,
                quantity: quantity - 1,
              },
            ],
            item.lineComponents ? item.merchandise.id : ""
          );
        }}
        cursor={updatingCart ? "wait !important" : undefined}
      />

      <Box
        width="20px"
        as="input"
        textAlign="center"
        type="number"
        fontFamily="Barlow"
        fontSize="18px"
        fontWeight={500}
        border="none"
        disabled={updatingCart || isSample}
        value={quantityInput}
        onChange={(event) => setQuantity(Number(event.target.value))}
        onBlur={(event) =>
          debouncedAdjustQuantity(
            [
              {
                id: item.id,
                quantity: Number(quantityInput),
                attributes: item.attributes,
              },
            ],
            item.lineComponents ? item.merchandise.id : ""
          )
        }
      />

      <StyledButton
        disabled={isSample}
        type="plus"
        onClick={(e) => {
          e.preventDefault();
          debouncedAdjustQuantity(
            [
              {
                id: item.id,
                quantity: quantity + 1,
                attributes: item.attributes,
              },
            ],
            item.lineComponents ? item.merchandise.id : ""
          );
        }}
        cursor={updatingCart ? "wait !important" : undefined}
      />
    </Flex>
  );
};
