import { useEffect, useState } from "react";
import { Box, Flex, Text } from "@storyofams/react-ui";
import { useShopify } from "~context";
import { CompanyForm } from "~components/company/CompanyForm";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { UnderlineLink } from "../Story/components/UnderlineLink";

export const WholesalePopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFormActive, setIsFormActive] = useState(false);
  const { customer, logout } = useShopify();
  const hasCompanyId =
    customer &&
    //@ts-ignore
    customer?.companyContacts?.edges[0]?.node?.company?.locations?.edges[0]
      ?.node.id;
  useEffect(() => {
    if (customer && !hasCompanyId) {
      setIsOpen(true);
    }
  }, [customer]);
  return (
    <Modal isDisabled overflow="visible" isOpen={isOpen}>
      <Box
        p={["20px", "30px"]}
        bg="#F7EFE7"
        position={"relative"}
        borderRadius={"8px"}
        maxHeight={"700px"}
        overflowY={"auto"}
      >
        <Box>
          {isFormActive ? (
            <CompanyForm closeModal={() => setIsOpen(false)} />
          ) : (
            <Box>
              <Text
                textAlign={"center"}
                fontFamily={"DINPro"}
                fontSize={["24px", "28px"]}
                fontWeight={700}
                px="10px"
                mb={2}
                css={{ textTransform: "uppercase" }}
              >
                Welkom bij wholesale
              </Text>
              <Text textAlign={"center"} mb={3}>
                We hebben gemerkt dat u nog niet geregistreerd bent voor
                wholesale. Meld u aan om toegang te krijgen tot wholesale
                prijzen.
              </Text>
              <Button
                variant="primary"
                width={"100%"}
                onClick={() => setIsFormActive(true)}
              >
                Aanmelden
              </Button>
              <Button
                mt={1}
                variant="secondary"
                width={"100%"}
                onClick={() => logout()}
              >
                Log uit
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
