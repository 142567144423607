import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Flex, Box, Text, Icon, Select } from "@storyofams/react-ui";
import axios from "axios";
import Link from "next/link";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { useShopify } from "~context";
import { showToast } from "~lib";
import { Button } from "~components/common/Button";
import { CountrySelect, PhoneInput } from "~components/common/Forms";
import { Checkmark } from "~components/common/Icons";
import { Input } from "~components/common/Input";

const schema = Yup.object().shape({
  firstName: Yup.string().required().trim().min(2),
  lastName: Yup.string().required().trim().min(2),
  email: Yup.string().trim().min(2),
  companyName: Yup.string().required().trim().min(2),
  territoryCode: Yup.string().required().trim().min(2),
  address1: Yup.string().required().trim().min(2),
  address2: Yup.string().trim(),
  zip: Yup.string().required().trim().min(2),
  state: Yup.string().required().trim().min(2),
  vat: Yup.string().trim(),
  website: Yup.string()
    .trim()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  kvk: Yup.string().required().trim().min(2),
  phone_number: Yup.string(),
  defaultAddress: Yup.boolean(),
});

export const CompanyForm = ({ closeModal }) => {
  const { customer, logout } = useShopify();
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [hasShop, setHasShop] = useState<any>({});
  const [isChecked, setIsChecked] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    const decodedCookies = decodeURIComponent(document.cookie).split(";");
    const { isReturningWholesale } = Object.assign(
      {},
      ...decodedCookies.map((cookie) => {
        const [key, value] = cookie.split("=");
        return { [key.trim()]: value };
      })
    );
    if (isReturningWholesale) {
      setIsFormSent(true);
    }
  }, [customer]);
  if (isFormSubmited || isFormSent)
    return (
      <Box>
        <Text
          textAlign={"center"}
          fontFamily={"DINPro"}
          fontSize={["24px", "28px"]}
          fontWeight={700}
          px="10px"
          mb={2}
          css={{ textTransform: "uppercase" }}
        >
          Gelukt!
        </Text>
        <Text textAlign={"center"} mb={3}>
          Bedankt voor het aanmelden. Ons team zal uw verzoek beoordelen en u de
          inloggegevens per e-mail toesturen.
        </Text>
        <Button
          onClick={() => {
            window.location.href = "https://www.naturalheroes.nl/";
          }}
          variant="primary"
          width={"100%"}
        >
          Verder Winkelen
        </Button>
        <Button
          mt={1}
          variant="secondary"
          width={"100%"}
          onClick={() => logout()}
        >
          Log uit
        </Button>
      </Box>
    );
  return (
    <>
      <Text
        textAlign={"center"}
        fontFamily={"DINPro"}
        fontSize={["24px", "28px"]}
        fontWeight={700}
        mb={[0, 1]}
      >
        Neem contact met ons op
      </Text>
      <Flex
        as="form"
        onSubmit={handleSubmit(async (address) => {
          const { data } = await axios.post(
            "/api/shopify/customer/companyCreate",
            {
              ...address,
              id: customer.id,
              hasShop: hasShop.value,
            }
          );
          if (
            data?.companyCreate?.userErrors &&
            data?.companyCreate?.userErrors.length
          )
            showToast(data.companyCreate.userErrors[0].message, "error");
          else {
            document.cookie = "isReturningWholesale=1";
            setIsFormSubmited(true);
          }
        })}
        flexDirection="column"
        minHeight="100%"
        py={3}
      >
        <Stack space={[1, 1]} flexDirection="column">
          <Flex justifyContent={"space-between"}>
            <Input
              width={"45%"}
              label={"Voornaam*"}
              {...register("firstName")}
              required
              error={
                !!errors?.firstName?.message &&
                String(errors?.firstName?.message)
              }
            />
            <Input
              width={"45%"}
              label={"Achternaam*"}
              {...register("lastName")}
              required
              error={
                !!errors?.lastName?.message && String(errors?.lastName?.message)
              }
            />
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Input
              width={"45%"}
              label={"E-mailadres*"}
              {...register("email")}
              disabled
              defaultValue={customer?.emailAddress?.emailAddress}
              error={!!errors?.email?.message && String(errors?.email?.message)}
            />
            <Input
              width={"45%"}
              label={"Bedrijfsnaam*"}
              {...register("companyName")}
              required
              error={
                !!errors?.companyName?.message &&
                String(errors?.companyName?.message)
              }
            />
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Input
              width={"45%"}
              label={"VAT"}
              {...register("vat")}
              error={!!errors?.vat?.message && String(errors?.vat?.message)}
            />
            <Input
              width={"45%"}
              label={"KVK Nummer*"}
              {...register("kvk")}
              required
              error={!!errors?.kvk?.message && String(errors?.kvk?.message)}
            />
          </Flex>
          <Input
            label={"Bedrijfswebsite"}
            {...register("website")}
            error={
              !!errors?.website?.message && String(errors?.website?.message)
            }
          />
          <Input
            label={"Adres 1*"}
            {...register("address1")}
            required
            error={
              !!errors?.address1?.message && String(errors?.address1?.message)
            }
          />

          <Input
            label={"Adres 2"}
            {...register("address2")}
            error={
              !!errors?.address2?.message && String(errors?.address2?.message)
            }
          />
          <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
            <Input
              mt={[1, 0]}
              width={["45%", "20%"]}
              label={"Postcode*"}
              {...register("zip")}
              required
              error={!!errors?.zip?.message && String(errors?.zip?.message)}
            />
            <Input
              mt={[1, 0]}
              order={1}
              width={["45%", "20%"]}
              label={"Stad*"}
              {...register("state")}
              required
              error={!!errors?.state?.message && String(errors?.state?.message)}
            />
            <Box width={["100%", "50%"]} order={[-1, 1]}>
              <Controller
                name="territoryCode"
                control={control}
                render={({ field }) => (
                  <CountrySelect
                    label={"Land/Regio*"}
                    required
                    isCompanyForm
                    error={errors?.territoryCode?.message}
                    {...field}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}
              />
            </Box>
          </Flex>
          <Select
            mb={1}
            //@ts-ignore
            value={hasShop}
            placeholder="Selecteer een optie"
            css={`
              border: 1px solid #c1bebd;
              border-radius: 8px;
              & > div {
                min-height: 47px;
                backgroud: red;
                border-radius: 7px !important;
                font-size: 16px;
                transition: border-color 0.18s ease-in-out,
                  background-color 0.18s ease-in-out;
              }
              &:not(:disabled) {
                &:hover,
                &:active,
                &:focus {
                  border-color: black;
                }
              }
              .react-select__option {
                &:hover {
                  background: #f4f3f0;
                }
              }
            `}
            onChange={(item) => setHasShop(item)}
            label="Heeft u een fysieke winkel?*"
            options={[
              { value: "Nee", label: "Nee" },
              { value: "Ja", label: "Ja" },
            ]}
          />
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <PhoneInput
                mb={1}
                label={"Telefoonnummer"}
                error={
                  errors?.phone_number?.message &&
                  String(errors?.phone_number?.message)
                }
                {...register("phone_number")}
                {...field}
              />
            )}
          />

          <Box mt="auto">
            <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
              <Flex mt={[1, 0]}>
                <Box
                  width={18}
                  borderRadius={"4px"}
                  height={18}
                  mr={1}
                  border="1px solid grey600"
                  onClick={() => setIsChecked((checked) => !checked)}
                >
                  {isChecked && <Icon icon={Checkmark}></Icon>}
                </Box>

                <Text>
                  {"Ik ga akkoord met de "}

                  <Text
                    onClick={() => {
                      window.open(
                        "https://www.naturalheroes.nl/privacy-beleid"
                      );
                      return false;
                    }}
                    cursor={"pointer"}
                    textDecoration={"underline"}
                    display={"inline"}
                  >
                    privacyverklaring
                  </Text>
                </Text>
              </Flex>
              <Button
                height={"48px"}
                minHeight={"48px"}
                mt={[2, 0]}
                width={["100%", "25%"]}
                variant="primary"
                isLoading={isSubmitting}
                disabled={!isDirty || !isChecked || !hasShop?.label}
              >
                Verzenden
              </Button>
            </Flex>
          </Box>
        </Stack>
      </Flex>
    </>
  );
};
